const faqData = [
  {
    question: "What is Ten-IdeaEngine?",
    answer:
      "Ten-IdeaEngine is an innovative platform that transforms your ideas and visions into actionable plans. It helps individuals, startups, and companies convert their thoughts into comprehensive blueprints for projects, ranging from website development and marketing campaigns to product roadmaps and business strategies.",
  },
  {
    question: "How does Ten-IdeaEngine help with project planning and execution?",
    answer:
      "Ten-IdeaEngine simplifies project planning and execution by generating detailed blueprints tailored to your specific requirements. Our intelligent system guides you through a conversational interface, asking relevant questions to capture all the necessary information. In just a few minutes, you'll have a comprehensive plan of action that outlines the scope, deliverables, and strategies for your project.",
  },
  {
    question: "Who can benefit from using Ten-IdeaEngine?",
    answer:
      "Ten-IdeaEngine is designed to benefit anyone with a project or idea, regardless of their level of expertise. Whether you're an entrepreneur, a marketing professional, a product manager, or a business owner, DayZero empowers you to create well-defined blueprints, even if you don't have the time or knowledge to do it yourself.",
  },
  {
    question: "How does Ten-IdeaEngine generate blueprints?",
    answer:
      "Ten-IdeaEngine utilizes advanced algorithms and industry best practices to generate blueprints. By analyzing the information you provide and leveraging our extensive knowledge base, Ten-IdeaEngine creates detailed plans that encompass the necessary components, features, technologies, and strategies for your project. It streamlines the process, saving you valuable time and ensuring a comprehensive roadmap for success.",
  },
  {
    question: "Can I customize the generated blueprints?",
    answer:
      "Yes, absolutely! Ten-IdeaEngine understands that every project is unique, and we provide you with the flexibility to customize the generated blueprints. While our system generates a detailed plan based on the information provided, you have the option to refine, modify, and add specific details according to your preferences. This ensures that the blueprint aligns perfectly with your vision and requirements.",
  },
  {
    question: "Does Ten-IdeaEngine also help me in executing my vision?",
    answer:
      "Yes, Ten-IdeaEngine not only helps you in planning and documenting your vision but also provides guidance and resources for executing it. Along with comprehensive blueprints, DayZero offers an AI Library with a repository of tools to enhance implementation. The Expert Network connects you with professionals for specialized expertise and support throughout the execution process. With Ten-IdeaEngine, you have the tools, resources, and guidance to execute your vision effectively and achieve success.",
  },
  {
    question: 'Is Ten-IdeaEngine suitable for both startups and established companies?',
    answer: 'Yes, Ten-IdeaEngine is suitable for both startups and established companies. Whether you are just starting out or have been in business for years, Ten-IdeaEngine provides a valuable solution for project planning and documentation. It helps startups establish a solid foundation for their ventures and assists established companies in refining their strategies and executing new initiatives.',
  },
  {
    question: 'Is my data secure on the Ten-IdeaEngine platform?',
    answer: 'Absolutely. we take data security and privacy seriously. We employ robust security measures to protect your data from unauthorized access, loss, or misuse. Your information is stored securely and treated with strict confidentiality, adhering to industry best practices and data protection regulations.',
  },
  {
    question: 'Can I integrate Ten-IdeaEngine with other project management tools?',
    answer: 'Launching soon for Premium users only. Ten-IdeaEngine is being designed to seamlessly integrate with other project management tools. We understand the importance of connectivity and collaboration across different platforms. By integrating with other tools, you can enhance your project management workflow, streamline processes, and ensure a smooth transition from planning to execution.',
  },
  {
    question: 'Can I collaborate with team members on Ten-IdeaEngine?',
    answer: 'Launching soon for Premium users only. Yes, Ten-IdeaEngine allows for seamless collaboration with your team members. You can invite team members to join your projects, share and discuss the generated blueprints, and work together on refining the scope, deliverables, and strategies. Collaboration ensures that everyone is aligned and working towards the same goals, enhancing overall project success.',
  },
];

export default faqData;
